import React from "react";

export const WaveTop = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270" {...props}>
      <path
        fill="#fff"
        d="m0 49 48-6.3c48-6.4 144-19 240-19.4C384 23 480 35 576 42s192 9 288 4.2c96-4.9 192-16.5 288-16.7 96-.2 192 11.2 240 16.8l48 5.7V0H0Z"
      />
    </svg>
  );
};

export const WaveBottom = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270" {...props}>
      <path
        fill="#fff"
        d="m0 288 48-42.7C96 203 192 117 288 112s192 69 288 106.7C672 256 768 256 864 240c96-16 192-48 288-48s192 32 240 48l48 16v64H0Z"
      />
    </svg>
  );
};
