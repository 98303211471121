import React from "react";

import Vegetables3 from "../assets/png/brands/vegetables3.png";
import Mail from "../assets/png/contactus/mail.png";
import Phone from "../assets/png/contactus/phone.png";
import Pin from "../assets/png/contactus/pin.png";
import Building from "../assets/png/contactus/building.png";

const ContactUs = () => {
  return (
    <section id="contact-us" className="relative bg-[#146E41]">
      <img
        src={Vegetables3}
        className="w-44 lg:w-auto absolute -top-14 left-0"
        alt=""
      />
      <div className="container px-4 pt-[8.31rem] pb-10 lg:pb-[3.94rem] relative">
        <div className="w-full relative">
          <div className="absolute -top-52 contact-box-shadow bg-white w-full lg:h-[10.88rem] rounded-[1.88rem]">
            <div className="p-4 lg:p-[2.63rem] grid md:grid-cols-2 lg:grid-cols-4 gap-3 items-center lg:h-full w-full text-peaGreen  font-medium text-[0.94rem] font-readex">
              <div className="flex items-center w-full gap-2 lg:gap-4">
                <img
                  src={Mail}
                  className="w-8 h-8 lg:w-auto lg:h-auto"
                  alt=""
                />
                <h5 className="text-xs lg:text-base">
                  Email <br />
                  Office@iaipng.com
                </h5>
              </div>
              <div className="flex items-center gap-2 lg:gap-4">
                <img
                  src={Phone}
                  className="w-8 h-8 lg:w-auto lg:h-auto"
                  alt=""
                />
                <h5 className="text-xs lg:text-base">
                  Phone: <br />
                  (+675) 7294 5305
                </h5>
              </div>
              <div className="flex items-center gap-4 lg:gap-5">
                <img src={Pin} className="w-8 h-8 lg:w-auto lg:h-auto" alt="" />
                <h5 className="text-xs lg:text-base">
                  Postal Address: PO Box 1462, Boroko, NCD, Papua New Guinea
                </h5>
              </div>
              <div className="flex items-center w-full gap-2 lg:gap-5">
                <img
                  src={Building}
                  className="w-8 h-8 lg:w-auto lg:h-auto"
                  alt=""
                />
                <h5 className="text-xs lg:text-base">
                  Office Location: PORTION 2529, 9 MILE National Capital
                  District Papua New Guinea
                </h5>
              </div>
            </div>
          </div>
          <h4 className="text-white text-[1.8rem] mb-2 lg:text-[3.13rem] text-center lg:mb-[2.69rem] font-bebas">
            Get In Touch
          </h4>
          <form className="w-full">
            <div className="grid w-full gap-8 mb-8">
              <input
                className="contact-input w-full font-readex"
                type="text"
                placeholder="Your Full Name"
              />
              <input
                className="contact-input font-readex"
                type="text"
                placeholder="Your Email"
              />
            </div>
            <textarea
              name="message"
              id="message"
              cols="30"
              rows="10"
              placeholder="Write your message"
              className="contact-textarea w-full p-3 lg:p-6 text-base lg:text-xl"
            />
            <button className="contact-btn mt-4 lg:mt-8 text-[1rem] lg:text-[1.88rem] hover:shadow-md hover:-translate-y-0.5 duration-300">
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
