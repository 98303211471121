import React from "react";
import Fade from "react-reveal/Fade";

// assets
import { AgroProject } from "../assets/svg/agro-project";
import { CircleArrowDownWhite } from "../assets/svg/Icons";
import playButton from "../assets/png/hero/playbutton.png";
import ellipsisHorizontal from "../assets/png/hero/ellipsis-horizontal.png";

const Hero = () => {
  return (
    <section id="home" className="relative">
      <div className="grid lg:grid-cols-2 gap-0">
        <div className="bg-[url('assets/png/bg-background-light-vegetables.png')] h-full w-full bg-center pt-6 pb-8 lg:pt-[3.25rem] lg:h-[860px]">
          <div className="px-2 lg:pl-[6.25rem]">
            <div className="xl:w-[519px]">
              <p className="font-bebas text-xl lg:text-[1.56rem] text-agroGreen leading-[1.88rem] font-normal">
                WELCOME TO
              </p>
              <h3 className="font-bebas text-3xl lg:text-5xl font-medium lg:leading-[3.5rem] break-words">
                INNOVATIVE AGRICULTURE ( AGRO ) INDUSTRY LIMITED
              </h3>
            </div>
            <div className="pr-5 mt-2">
              <h5 className="text-base leading-normal">
                Innovative Agriculture (Agro) Industry Ltd. (IAI) is a member of
                the LR Group, a leading Project Development Company, active
                worldwide since 1986. The Group completed over 400 profitable
                projects in Africa, Asia, Europe, Oceania and South America in
                the fields of:
              </h5>
            </div>
            <div className="mt-3 lg:mt-8">
              <Fade left>
                <AgroProject />
              </Fade>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <a href="#about-us">
              <button className="flex items-center justify-center gap-[0.73rem] w-[8.13rem] h-[3.06rem] bg-[#F67B00] rounded-[0.31rem] text-white text-[0.94rem] hover:-translate-y-1 duration-300">
                About us <CircleArrowDownWhite />
              </button>
            </a>
          </div>
        </div>
        <div className="bg-[url('assets/png/hero-agro-vegetable.png')] bg-cover bg-center bg-no-repeat h-60 w-full pb-6 md:h-80 lg:h-auto lg:pb-[78px]">
          <div className="flex items-center justify-center h-full">
            <img
              src={playButton}
              className="w-14 h-14 lg:w-auto lg:h-auto"
              alt=""
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src={ellipsisHorizontal}
              className="w-14 h-full lg:w-auto lg:h-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
