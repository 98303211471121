import React, { useEffect, useState } from "react";

import {
  AgroIndustryLogo,
  BuildingIcon,
  FacebookIcon,
  FastEmail,
  GmailIcon,
  LinkendIcon,
  PhoneIcon,
  PinICon,
  YoutubeIcon,
} from "../assets/svg/Icons";
import Hamburger from "./ui/hamburger";
import { useDrawer } from "./ui/drawer-views/context";

export const SubHeader = () => {
  return (
    <section className="bg-white hidden lg:block">
      <div className="container mx-auto px-4 h-12 flex items-center justify-between z-50">
        <ul className="flex items-center justify-center text-[10px] text-agroGreen font-medium gap-[1.12rem]">
          <li className="flex items-center gap-[0.60rem]">
            <FastEmail />
            <p>Office@iaipng.com</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <PhoneIcon />
            <p>(+675) 7294 5305</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <PinICon />
            <p>PO Box 1462, Boroko, NCD, Papua tNew Guinea</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <BuildingIcon />
            <p>
              PORTION 2529, 9 MILE National Capital District Papua New Guinea
            </p>
          </li>
        </ul>
        <div>
          <ul className="flex items-center justify-center gap-x-[0.81rem]">
            <li>
              <LinkendIcon className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
            </li>
            <li>
              <FacebookIcon className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
            </li>
            <li className="pt-[2px]">
              <YoutubeIcon className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
            </li>
            <li className="pt-[2px]">
              <GmailIcon className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export const hashLinkList = [
  {
    href: "#home",
    name: "Home",
  },
  {
    href: "#about-us",
    name: "About Us",
  },
  {
    href: "#our-projects",
    name: "Our Projects",
  },
  {
    href: "#our-brands",
    name: "Our Brands",
  },
  {
    href: "#contact-us",
    name: "Contact Us",
  },
  {
    href: "#media",
    name: "Media",
  },
];

const Header = () => {
  // const haseUrl = window.location.hash;
  const [haseUrl, setHaseUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { openDrawer } = useDrawer();

  useEffect(() => {
    const handleHashChange = () => {
      setHaseUrl(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <div className="sticky top-0 z-30">
      <SubHeader />
      <header className="py-4 lg:h-[7.25rem] bg-agroGreen text-white box-shadow">
        <div className="lg:container mx-auto px-4 flex items-center justify-between h-full">
          <div>
            <AgroIndustryLogo className="w-full" />
          </div>
          <nav className="hidden lg:block">
            <ul className="flex items-center font-bold text-xl gap-x-6 xl:gap-x-12">
              {hashLinkList.map((item) => {
                return (
                  <li className="relative group">
                    <a href={item.href}>{item.name}</a>
                    <span
                      className={`h-[1px] inline-block bg-white absolute left-0 -bottom-0.5 group-hover:w-full transition-[width] ease duration-300 ${
                        haseUrl === item.href ? "w-full" : "w-0"
                      }`}
                    >
                      &nbsp;
                    </span>
                  </li>
                );
              })}
            </ul>
          </nav>
          <Hamburger
            className="lg:hidden"
            isOpen={isOpen}
            onClick={() => openDrawer("MOBILE_MENU")}
          />
        </div>
      </header>
    </div>
  );
};

export default Header;
