import React from "react";

import {
  AgroIndustryLogoSmall,
  FacebookWhite,
  GmailWhite,
  LinkendIcon,
  LinkendWhite,
  YoutubeWhite,
} from "../assets/svg/Icons";

const Footer = () => {
  return (
    <div className="footer-bg w-full">
      <div className="container px-4 pt-8 lg:pt-[4.38rem] pb-[1.81rem]">
        <div className="relative">
          <AgroIndustryLogoSmall className="mb-4 lg:absolute -top-5 right-0" />
          <h5 className="footer-text-shadow text-[2rem] text-white font-bebas lg:text-[3.13rem] leading-normal">
            INNOVATIVE AGRICULTURE ( AGRO ) INDUSTRY LIMITED
          </h5>
          <h5 className="footer-text-shadow text-white text-sm lg:text-xl font-readex">
            P.O Box 1462 Boroko NCD, Papua New Guinea | Office@iaipng.com
          </h5>
          <div className="mt-[1.19rem] lg:mt-[2.19rem]">
            <ul className="flex items-center gap-[1.18rem]">
              <li>
                <LinkendWhite className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
              </li>
              <li>
                <FacebookWhite className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
              </li>
              <li>
                <YoutubeWhite className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
              </li>
              <li>
                <GmailWhite className="hover:-translate-y-0.5 duration-300 cursor-pointer" />
              </li>
            </ul>
          </div>
          <div className="mt-[3.24rem] lg:mt-[5.63rem]">
            <ul className="flex flex-wrap items-center gap-[1.18rem] text-lemonGreen font-readex">
              <li className="text-white text-[0.8rem] lg:text-[1.13rem] footer-text-shadow font-normal">
                Site Map
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] footer-text-shadow">
                <a href="#about-us">About Us</a>
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] font-light footer-text-shadow">
                <a href="#our-projects">Our Projects</a>
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] font-light footer-text-shadow">
                <a href="#our-vision">Our Vision</a>
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] font-light footer-text-shadow">
                <a href="#our-brands">Our Brands</a>
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] font-light footer-text-shadow">
                <a href="#media">Media</a>
              </li>
            </ul>
          </div>
          <div className="mt-[2.19rem]">
            <ul className="flex items-center gap-[1.18rem] text-lemonGreen font-readex">
              <li className="text-white text-[0.8rem] lg:text-[1.13rem] footer-text-shadow font-normal">
                External Links
              </li>
              <li className="text-[0.8rem] lg:text-[1.13rem] font-light footer-text-shadow">
                LR Group
              </li>
            </ul>
          </div>
          <div className="mt-[2rem] lg:mt-[3.69rem]">
            <h5 className="text-white font-readex text-xs text-center">
              ©2023 Innovative Agriculture(Agro) Industry Limited. All Rights
              Reserved
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
