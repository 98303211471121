import "./App.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Gallery from "./components/Gallery";
import Projects from "./components/Projects";
import Faq from "./components/Faq";
import Vision from "./components/Vision";
import OurBrands from "./components/OurBrands";
import Media from "./components/Media";
import NewsFeed from "./components/NewsFeed";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Menu from "./components/ui/menu";
import DrawerContainer from "./components/ui/drawerContainer";

function App() {
  return (
    <div className="font-reem">
      <Header />
      <DrawerContainer />
      <Hero />
      <AboutUs />
      <Gallery />
      <Projects />
      <Faq />
      <Vision />
      <OurBrands />
      <Media />
      <NewsFeed />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
