import React from "react";

import galleryImage1 from "../assets/png/gallery/1.png";
import galleryImage2 from "../assets/png/gallery/2.png";
import galleryImage3 from "../assets/png/gallery/3.png";
import galleryImage4 from "../assets/png/gallery/4.png";
import galleryImage5 from "../assets/png/gallery/5.png";
import galleryImage6 from "../assets/png/gallery/6.png";
import galleryImage7 from "../assets/png/gallery/7.png";
import galleryImage8 from "../assets/png/gallery/8.png";

const Gallery = () => {
  const images = [
    { src: galleryImage1 },
    { src: galleryImage2 },
    { src: galleryImage3 },
    { src: galleryImage4 },
    { src: galleryImage5 },
    { src: galleryImage6 },
    { src: galleryImage7 },
    { src: galleryImage8 },
    { src: galleryImage1 },
    { src: galleryImage2 },
    { src: galleryImage3 },
    { src: galleryImage4 },
  ];

  return (
    <div className="w-full">
      <div>
        <div className="gallery">
          {images.map((image, index) => {
            return (
              <div className="pics" key={index}>
                <img src={image.src} alt="" className="bg-cover w-full" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
