import { atom, useAtom } from "jotai";

export const DRAWER_VIEW = "DRAWER_MENU";

const drawerAtom = atom({ isOpen: false, view: "DRAWER_MENU" });

export function useDrawer() {
  const [state, setState] = useAtom(drawerAtom);
  const openDrawer = (view: DRAWER_VIEW) =>
    setState({ ...state, isOpen: true, view });
  const closeDrawer = () => setState({ ...state, isOpen: false });
  return {
    ...state,
    openDrawer,
    closeDrawer,
  };
}
