import React from "react";
import Fade from "react-reveal/Fade";

import farm from "../assets/png/farm-aboutUs.png";
import wheat from "../assets/png/wheat.png";

const AboutUs = () => {
  return (
    <section id="about-us" className="relative">
      <img
        className="w-52 lg:w-auto absolute right-0 top-0 z-0"
        src={wheat}
        alt=""
      />
      <div className="px-2 pt-10 md:px-4 lg:px-[6.25rem] lg:pt-[5.19rem] pb-[3.31rem] flex flex-col">
        <div className="w-full">
          <h5 className="text-xl text-agroGreen uppercase font-bebas lg:text-[1.56rem]">
            about us
          </h5>
          <div className="text-base md:text-lg lg:text-xl lg:w-[773px] text-peaGreen flex flex-col gap-3 lg:gap-9 text-justify mt-4 lg:mt-10 relative z-10">
            <h5>
              IAI began its activities in Papua New Guinea (PNG) agricultural
              sector in 2011. IAI’s projects in PNG encompass a wide range of
              activities such as farming, greenhouses, poultry, processing and
              dairy. All projects integrate and implement Israeli knowledge and
              expertise leading to enhanced production capabilities and
              profitability.
            </h5>
            <h5>
              Our projects generated over 1,000 new direct job opportunities
              employing Papua New Guineans of all walks of life, from university
              graduates, professionals to general staff.
            </h5>
            <h5>
              Our projects further engage hundreds of small scale farmers that
              supply our Agro Industrial Centres (AIC), turning subsistence
              farmers into cash cropping farmers.
            </h5>
            <h5>
              Each Agro Industrial Centre (AIC) is tailored for its location and
              the market demand, and together they create a value and supply
              chain providing food security, import replacement and local
              capacity building.
            </h5>
          </div>
        </div>
        <div className="w-full">
          <img
            className="relative xl:absolute right-6 top-10 left-[50%] translate-x-[-50%] xl:left-[60%] xl:translate-x-[-0%] xl:top-32 z-10"
            src={farm}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
