import React from "react";

import LlimoDairy from "../assets/png/brands/Llimo-Dairy.png";
import KukFarm from "../assets/png/brands/kuk.png";
import JiwakaFarm from "../assets/png/brands/jiwaka.png";
import WigmanFarm from "../assets/png/brands/wigman.png";
import Joi from "../assets/png/brands/joi.png";
import Sirunki from "../assets/png/brands/sirunki.png";
import MountFubilan from "../assets/png/brands/mount-fubilan.png";
import FlyVanilla from "../assets/png/brands/fly-vanilla.png";
import NineMile from "../assets/png/brands/9-mile.png";
import SepikFresh from "../assets/png/brands/sepik-fresh.png";
import Vegetables2 from "../assets/png/brands/vegetable2.png";

const OurBrands = () => {
  const brandList = [
    {
      id: 1,
      image: LlimoDairy,
      name: "ilimo dairy farm",
      description: "Real Milk! Real Local",
    },
    {
      id: 2,
      image: KukFarm,
      name: "kuk farm",
      description: "Perfect Potato & KauKau for the perfect chip!",
    },
    {
      id: 3,
      image: JiwakaFarm,
      name: "jiwaka produce",
      description: "",
    },
    {
      id: 4,
      image: WigmanFarm,
      name: "wigman",
      description: "",
    },
    {
      id: 5,
      image: Joi,
      name: "joi by ilimo",
      description: "Real Fruit, Natural Flavours",
    },
    {
      id: 6,
      image: Sirunki,
      name: "sirunki farm",
      description: "Enga Fresh Produce",
    },
    {
      id: 7,
      image: MountFubilan,
      name: "mount fubilan farm",
      description: "Quality Eggs",
    },
    {
      id: 8,
      image: FlyVanilla,
      name: "fly vanilla",
      description: "",
    },
    {
      id: 9,
      image: NineMile,
      name: "9 mile farm",
      description: "Quality Fresh Produce",
    },
    {
      id: 10,
      image: SepikFresh,
      name: "sepik fresh farm",
      description: "Kaikai bilong ol Oris PS!",
    },
  ];
  return (
    <section id="our-brands" className="relative z-10">
      <div className="container px-4 pt-0 lg:pt-16 pb-8">
        <div className="w-full">
          <h3 className="text-[1.8rem] lg:text-[3.13rem] text-peaGreen uppercase font-bebas text-shadow">
            our brands
          </h3>
          <span className="h-1 lg:h-2 inline-block w-14 lg:w-[5.38rem] bg-lemonGreen">
            &nbsp;
          </span>
        </div>
        <div className="mt-4 md:mt-8 lg:mt-[3.44rem]">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-9">
            {brandList.map((brand, id) => {
              return (
                <div className="brand-card z-10" key={id}>
                  <img
                    src={brand.image}
                    className="rounded-[1.25rem] w-full bg-cover bg-center"
                    alt={brand.name}
                  />
                  <div className="p-4">
                    <div className="h-20">
                      <h5 className="text-[1.56rem] uppercase font-normal font-bebas">
                        {brand.name}
                      </h5>
                      <h5 className="font-readex font-normal text-xs">
                        {brand.description}
                      </h5>
                    </div>
                    <div className="flex justify-center mt-[2.19rem] mb-2.5">
                      <button className="py-[0.44rem] text-xs lg:p-[0.44rem] rounded-full bg-peaGreen text-white w-full max-w-[9.88rem] font-readex hover:-translate-y-0.5 duration-300">
                        Read more
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <img
        src={Vegetables2}
        className="absolute w-36 lg:w-auto right-0 -bottom-32 z-0"
        alt=""
      />
    </section>
  );
};

export default OurBrands;
