import React from "react";

import media1 from "../assets/png/media/media1.png";
import media2 from "../assets/png/media/media2.png";
import media3 from "../assets/png/media/media3.png";
import media4 from "../assets/png/media/media4.png";
import media5 from "../assets/png/media/media5.png";
import media6 from "../assets/png/media/media6.png";
import youtubeLogo from "../assets/png/media/youtube-icon.png";

const Media = () => {
  const mediaList = [
    {
      id: 1,
      image: media1,
    },
    {
      id: 2,
      image: media2,
    },
    {
      id: 3,
      image: media3,
    },
    {
      id: 4,
      image: media4,
    },
    {
      id: 5,
      image: media5,
    },
    {
      id: 6,
      image: media6,
    },
  ];
  return (
    <section id="media" className="container px-4">
      <div className="py-4 lg:py-8">
        <div className="w-full">
          <h3 className="text-[1.8rem] lg:text-[3.13rem] text-peaGreen uppercase font-bebas text-shadow">
            media
          </h3>
          <span className="h-1 lg:h-2 inline-block w-14 lg:w-[5.38rem] bg-lemonGreen">
            &nbsp;
          </span>
        </div>
        <div className="mt-4 md:mt-8 lg:mt-[3.94rem]">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
            {mediaList.map((media) => {
              return (
                <div
                  className="relative hover:opacity-90 cursor-pointer"
                  key={media.id}
                >
                  <img
                    src={media.image}
                    className="w-full bg-cover bg-center"
                    alt=""
                  />
                  <img
                    src={youtubeLogo}
                    className="w-10 sm:w-14 lg:w-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50"
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Media;
