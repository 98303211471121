import React from "react";
import { WaveBottom, WaveTop } from "../assets/svg/wave";
// import wave from "../assets/png/wave.png";
import { ArrowDownCircle } from "../assets/svg/Icons";

const Faq = () => {
  const Faq = [
    {
      title: "isr-png 9 mile vegetable farm",
    },
    {
      title: "hela hydro feasibility study ( FS )",
    },
    {
      title: "enga agro industrial center",
    },
    {
      title: "ilimo daury farm",
    },
    {
      title: "koroba agro industrial center",
    },
    {
      title: "tari - piwa agro industrial center",
    },
    {
      title: "mount fubilan agro industry poultry integration",
    },
    {
      title: "‘kuk’ frozen vegetables and french fries",
    },
    {
      title: "sepik agro industrial center",
    },
    {
      title: "hela coffee feasibility study ( fs )",
    },
    {
      title: "south fly agro industrial center",
    },
    {
      title: "fly vailla - samagus, kiunga agro industrial center",
    },
    {
      title: "solar power irrigation systems ( spfs ) - in the philippines",
    },
  ];
  return (
    <section className="relative p-0 m-0">
      {/*<img src={wave} className="w-full absolute top-0 " alt="" />*/}
      <WaveTop className="absolute w-full -top-[0.9px]" />
      <WaveTop className="absolute w-full -bottom-1 rotate-180" />
      {/*<WaveBottom className="absolute w-full bottom-0 left-0" />*/}
      <div className="faq-background bg-cover bg-center bg-no-repeat py-8 md:pt-14 md:pb-14 lg:pt-[12.25rem] lg:pb-[20rem]">
        <div className="container mx-auto px-4">
          {Faq.map((item, id) => {
            return (
              <div
                className="bg-white rounded-[0.63rem] py-2 px-3 lg:py-4 lg:px-6 mt-[0.31rem] flex flex-row items-center justify-between"
                key={id}
              >
                <p className="font-bebas text-peaGreen text-xl lg:text-3xl">
                  {item.title}
                </p>
                <ArrowDownCircle />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
