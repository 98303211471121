import React from "react";
import Fade from "react-reveal/Fade";

// assets
import PapauMap from "../assets/png/papau-map.png";
import Koroba from "../assets/png/projects/Koroba.png";
import TariPiwa from "../assets/png/projects/TariPiwa.png";
import Tabubil from "../assets/png/projects/Tabubil.png";
import Sanagus from "../assets/png/projects/Sanagus.png";
import SouthFly from "../assets/png/projects/South-fly.png";
import LlimoDairy from "../assets/png/projects/Llimo-Dairy.png";
import JoiJuice from "../assets/png/projects/Joi-Juice.png";
import NineMile from "../assets/png/projects/9-mile.png";
import SouthernHighLand from "../assets/png/projects/Souther-HighLand.png";
import Jiwaka from "../assets/png/projects/Jiwaka.png";
import SepikAgro from "../assets/png/projects/Sepik.png";
import Enga from "../assets/png/projects/Enga.png";

const Projects = () => {
  const location = [
    {
      id: 1,
      img: Koroba,
      location: "koroba agro industrial center ( aic )",
    },
    {
      id: 2,
      img: TariPiwa,
      location: "tari piwa agro industrial centre ( aic)",
    },
    {
      id: 3,
      img: Tabubil,
      location: "tabubil (ok tedi) poultry integration",
    },
    {
      id: 4,
      img: Sanagus,
      location: "sanagus, kiunga agro industrial\n" + "center ( aic )",
    },
    {
      id: 5,
      img: SouthFly,
      location: "south fly agro industrial center (aic)",
    },
    {
      id: 6,
      img: LlimoDairy,
      img2: JoiJuice,
      location: "llimo dairy farm, joi juice & water",
    },
    {
      id: 7,
      img: NineMile,
      location: "9 mile farm",
    },
    {
      id: 8,
      img: SouthFly,
      location: "sogeri agro industrial ( aic )",
    },
    {
      id: 9,
      img: SouthernHighLand,
      location: "southern highlands frozen chips enterprise",
    },
    {
      id: 10,
      img: Jiwaka,
      location: "jiwaka agro industrial center ( aic )",
    },
    {
      id: 11,
      img: SepikAgro,
      location: "sepik agro indsutrial center ( aic )",
    },
    {
      id: 12,
      img: Enga,
      location: "enga agro industrial center ( aic )",
    },
    {
      id: 13,
      img: SouthFly,
      location: "namatanai agro industry",
    },
  ];

  return (
    <section id="our-projects" className="container mx-auto px-4">
      <div className="py-[4.44rem]">
        <div className="grid gap-3 md:grid-cols-2 lg:grid-rows-5 lg:grid-cols-3 lg:grid-flow-col lg:gap-[1.31rem]">
          <img
            className="px-2 lg:pl-8 lg:col-end-4 lg:col-span-2 lg:row-end-3 lg:row-span-4"
            src={PapauMap}
            alt="PapauMap"
          />
          {location.map((item, id) => {
            return (
              <Fade bottom>
                <div
                  className="card-project relative flex items-center py-1 lg:py-3 pl-[44px] pr-[11.56px]"
                  key={id}
                >
                  <span className="number absolute top-0 left-0">
                    <h3 className="text-[41px] font-bebas text-white text-center">
                      {item.id}
                    </h3>
                  </span>
                  <div className="flex items-center justify-center gap-[0.27rem] pl-[0.42rem]">
                    <img src={item.img} alt={item.img} />
                    <img src={item.img2} alt={item.img2} />
                    <h3 className="uppercase font-bebas text-xl">
                      {item.location}
                    </h3>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
