import React from "react";
import Fade from "react-reveal/Fade";

// assets
import VisionImage2 from "../assets/png/vision/vision-photo2.png";
import Plant from "../assets/png/vision/plant.png";

const Vision = () => {
  const visionList = [
    {
      title: "Proven track record in high-growth economies",
    },
    {
      title:
        "Creative tailor-made solutions and multi-disciplinary capabilities",
    },
    {
      title:
        "Innovative technology and a deep understanding of local market dynamics",
    },
    {
      title:
        "End-to-end solution capabilities in executing greenfield projects, including\n" +
        "experience in initiating, launching, executing and managing a range of ventures",
    },
    {
      title: "Proven ability in raising funds and investments",
    },
    {
      title:
        "Knowledge transfer – establish an array of local professionals that will continue to \n" +
        "tdeliver and implement their acquired skills throughout and beyond project life",
    },
    {
      title:
        "Focus on sustainability – outr projects integrate social and environmental aspects, \n" +
        "ensuring long-term positive impact on involved communities",
    },
  ];
  return (
    <section id="our-vision" className="container px-4">
      <div className="pt-10 lg:pt-16 lg:pb-8">
        <div className="w-full">
          <h3 className="text-[1.8rem] lg:text-[3.13rem] text-peaGreen uppercase font-bebas text-shadow">
            our vision
          </h3>
          <span className="h-1 lg:h-2 inline-block w-14 lg:w-[5.38rem] bg-lemonGreen">
            &nbsp;
          </span>
        </div>
        <div className="grid lg:grid-cols-2">
          <div className="flex flex-col gap-4 text-peaGreen font-readex">
            <h5 className="text-2xl lg:text-[2.63rem]  lg:leading-[3.25rem]">
              Developing sustainable and profitable agriculture projects
            </h5>
            <span className="text-base leading-5">
              that improve standard of living by creating employment, investing
              in downstream processing, capacity building and training; leading
              to better access to market (both domestic & international) turning
              subsistence farmers into cash-cropping farmers.
            </span>
            <div>
              <h3 className="font-medium text-base lg:text-xl">
                What makes us Unique:
              </h3>
              <Fade left>
                <div className="mt-[0.90rem] ml-3 lg:ml-6">
                  <ul className="flex flex-col gap-y-2 ">
                    {visionList.map((item, id) => {
                      return (
                        <li
                          className="flex items-center gap-[0.90rem]"
                          key={id}
                        >
                          <img src={Plant} alt="plant" />
                          <h5 className="text-base">{item.title}</h5>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex lg:justify-end relative">
            <Fade right>
              <img
                src={VisionImage2}
                className="mt-8 lg:mt-0 lg:absolute lg:-right-18 lg:top-0"
                alt="farmer-vegetables"
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
