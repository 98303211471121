import React from "react";
import {
  AgroIndustryLogo,
  BuildingIcon,
  FastEmail,
  PhoneIcon,
  PinICon,
  XmarkIcon,
} from "../../assets/svg/Icons";
import { useDrawer } from "./drawer-views/context";
import { hashLinkList, SubHeader } from "../Header";

const Menu = () => {
  const { closeDrawer } = useDrawer();
  return (
    <div className="top-0 z-40 h-full w-full max-w-full border-dashed border-gray-200 bg-body relative font-bebas">
      <div className="relative flex items-center justify-between overflow-hidden px-4 py-4 2xl:px-8 bg-agroGreen">
        <AgroIndustryLogo className="w-full" />
        <div>
          <XmarkIcon onClick={closeDrawer} />
        </div>
      </div>
      <div className="mt-10">
        <ul className="flex flex-col gap-4  font-semibold text-xl gap-x-6 xl:gap-x-12">
          {hashLinkList.map((item) => {
            return (
              <li className="relative group text-center tracking-wider">
                <a href={item.href} onClick={closeDrawer}>
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="bg-peaGreen p-6 absolute bottom-0 w-full font-readex">
        <ul className="flex flex-col justify-center text-[10px] text-gray-50 gap-[1.12rem]">
          <li className="flex items-center gap-[0.60rem]">
            <FastEmail />
            <p>Office@iaipng.com</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <PhoneIcon />
            <p>(+675) 7294 5305</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <PinICon />
            <p>PO Box 1462, Boroko, NCD, Papua tNew Guinea</p>
          </li>
          <li className="flex items-center gap-[0.60rem]">
            <BuildingIcon />
            <p>
              PORTION 2529, 9 MILE National Capital District Papua New Guinea
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
