import React from "react";

import newsFeed1 from "../assets/png/newsfeed/newsfeed1.png";
import newsFeed2 from "../assets/png/newsfeed/newsfeed2.png";
import newsFeed3 from "../assets/png/newsfeed/newsfeed3.png";
import newsFeed4 from "../assets/png/newsfeed/newsfeed4.png";
import youtubeLogo from "../assets/png/media/youtube-icon.png";
import youtubeLogoLarge from "../assets/png/newsfeed/youtube-icon-large.png";

const NewsFeed = () => {
  return (
    <div className="container px-4">
      <div className="pt-4 pb-[8rem] lg:pt-8 lg:pb-[12.06rem]">
        <div className="w-full">
          <h3 className="text-[1.8rem] lg:text-[3.13rem] text-peaGreen uppercase font-bebas text-shadow">
            newsfeed
          </h3>
          <span className="h-1 lg:h-2 inline-block w-14 lg:w-[5.38rem] bg-lemonGreen">
            &nbsp;
          </span>
        </div>
        <div className="mt-4 lg:mt-[3.06rem]">
          <div className="grid md:grid-rows-3 md:grid-flow-col gap-4">
            <div className="md:row-span-3 relative hover:opacity-90 cursor-pointer">
              <img
                src={newsFeed1}
                className="w-full h-full bg-cover bg-center"
                alt="news feed"
              />
              <img
                src={youtubeLogoLarge}
                className="w-10 sm:w-14 lg:w-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50"
                alt=""
              />
            </div>
            <div className="relative hover:opacity-90 cursor-pointer">
              <img
                src={newsFeed2}
                className="w-full h-full bg-cover bg-center"
                alt="news feed"
              />
              <img
                src={youtubeLogo}
                className="w-10 sm:w-14 lg:w-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50"
                alt=""
              />
            </div>
            <div className="relative hover:opacity-90 cursor-pointer">
              <img
                src={newsFeed3}
                className="w-full h-full bg-cover bg-center"
                alt="news feed"
              />
              <img
                src={youtubeLogo}
                className="w-10 sm:w-14 lg:w-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50"
                alt=""
              />
            </div>
            <div className="relative hover:opacity-90 cursor-pointer">
              <img
                src={newsFeed4}
                className="w-full bg-cover bg-center"
                alt="news feed"
              />
              <img
                src={youtubeLogo}
                className="w-10 sm:w-14 lg:w-auto absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsFeed;
